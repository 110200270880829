import React, { useState, useEffect, useRef } from 'react';
import revliLogo from 'assets/icons/logo-dark.svg';
import iconArrowDown from 'assets/icons/icon-arrow-down.svg';
import iconAccount from 'assets/icons/icon-account.svg';
import iconBilling from 'assets/icons/icon-billing.svg';
import iconLogout from 'assets/icons/icon-logout.svg';
import { useNavigate } from 'react-router-dom';
import { clearAuthToken } from 'slices/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'utils/utils';
import Loader from 'components/Loader/Loader';
import { fetchQuery } from 'relay';
import moment from 'moment';
import { GetUserSubscriptionQuery } from 'services/queries/GetUserSubscriptionQuery';
import { shutdown } from 'components/Intercom';
import CHOICES from 'CHOICES';
// import { GetFundingInsightsUserSubscriptionQuery } from 'services/queries/GetFundingInsightsUserSubscriptionQuery';

const Header = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const node = useRef();
  // const [subscription, setSubscription] = useState({});
  // const [isFundingSubs, setIsFundingSubs] = useState({});
  const currentUser = useSelector((state) => state.auth.currentUser);
  // const subscription = useSelector((state) => state.auth.subsCription);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);

  const getSubscription = () => {
    let trialDays = moment(currentUser?.trialActiveTill).diff(moment(), 'hours') / 24;
    if (!currentUser?.isUserOnFreeTrial) {
      trialDays = -1;
    }
    if (trialDays >= 0.5) {
      if (trialDays > Math.floor(trialDays)) {
        trialDays = trialDays + 1;
      }
    }

    // setTrialDaysRemaining(Math.floor(trialDays));
    fetchQuery(GetUserSubscriptionQuery, {})
      .toPromise()
      .then((data) => {
        if (data.getUserSubscription) {
          // setSubscription(data.getUserSubscription);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  // To check if the funding subscribed or not
  // const getFundingSubscription = () => {
  //   setLoading(true);
  //   fetchQuery(GetFundingInsightsUserSubscriptionQuery, {})
  //     .toPromise()
  //     .then((data) => {
  //       setLoading(false)
  //       if (data?.getFundingInsightsUserSubscription) {
  //         console.log(data?.getFundingInsightsUserSubscription)
  //         setIsFundingSubs(data.getFundingInsightsUserSubscription)
  //       }
  //     });
  // }

  useEffect(() => {
    getSubscription();
    // getFundingSubscription();
    // eslint-disable-next-line
  }, [currentUser]);

  const handleClick = (e) => {
    if (openModal) {
      setOpenModal(false);
      return;
    }
    if (!isEmpty(node) && node?.current?.contains(e.target)) {
      return;
    }
    setOpenModal(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) return <Loader />;
  return (
    <header className="primary-header" ref={node} onClick={handleClick}>
      <img
        className="cursor-pointer"
        onClick={() => navigate('/funding')}
        src={revliLogo}
        alt="revli-logo"
      />
      <nav className="nav-list-wrapper ml-8">
        <li
          className={
            'nav-list ' +
            (window.location.pathname.includes('inbox') &&
            !window.location.pathname.includes('admin')
              ? 'active'
              : '')
          }
          onClick={() => navigate('/inbox')}
        >
          Inbox Warmup
        </li>
        <li
          className={
            'nav-list ' +
            (window.location.pathname.includes('funding') ||
            window.location.pathname.includes('funding-reports')
              ? 'active'
              : '')
          }
          onClick={() => navigate(`/funding`)}
        >
          Funding Insights
        </li>
        {/*<li*/}
        {/*  className={*/}
        {/*    'nav-list ' + (window.location.pathname.includes('email-test') ? 'active' : '')*/}
        {/*  }*/}
        {/*  onClick={() => navigate(`email-test/${currentUser?.id}`)}>*/}
        {/*  Email Spam Test*/}
        {/*</li>*/}
        {currentUser?.dashboardAccess === CHOICES.DashBoardAccessType.ADMINISTRATIVE_VIEW && (
          <li
            className={'nav-list ' + (window.location.pathname.includes('admin') ? 'active' : '')}
            onClick={() => navigate('/administrator-view')}
          >
            Admin View
          </li>
        )}
      </nav>
      {/* {isEmpty(subscription) && (
        <div className="flex items-center mx-auto">
          <p className="text-25">
            {(isEmpty(isFundingSubs)) ?
              <>   
              {trialDaysRemaining !== null &&
                (trialDaysRemaining >= 0
                  ? trialDaysRemaining === 0
                    ? 'Your free trial ends today.'
                    : `Your free trial ends in ${trialDaysRemaining} ${trialDaysRemaining > 1 ? 'days' : trialDaysRemaining === 1 ? 'day' : ''
                    }.`
                  : `Your free trial has ended.`)}
              </> : <></>
            }
          </p>
          {isEmpty(isFundingSubs) ? 
           <button
           className="btn btn--primary btn--sm flex item-center mx-2"
           disabled={window.location.pathname === '/billing'}
           onClick={() => navigate('/billing?upgrade=true')}>
           Upgrade Now
         </button>:<></>
          }
        </div>
      )} */}
      <div className="profile-avatar relative flex items-center ml-auto cursor-pointer">
        <div className="mr-3 profile-avatar-name">
          <h4 className="font-medium text-right">
            {currentUser?.firstName + ' ' + currentUser?.lastName}
          </h4>
          <p className="text-secondary text-right lh-xs">{currentUser?.email}</p>
        </div>
        <div className="flex items-center" onClick={() => setOpenModal(!openModal)}>
          <figure
            className="avatar-container avatar-container--cover"
            style={{ width: 36, height: 36 }}
          >
            {currentUser?.profileUrl ? (
              <img
                src={currentUser?.profileUrl}
                alt="profile-avatar"
                referrerPolicy="no-referrer"
              />
            ) : (
              <p className="text-3xl font-bold">
                {currentUser?.firstName?.charAt(0).toUpperCase()}
              </p>
            )}
          </figure>
          <img src={iconArrowDown} alt="icon-arrow-down" className="ml-3" />
        </div>
        <div
          className={'dropdown-menu p-2 ' + (openModal ? 'open' : '')}
          style={{ top: 'calc(100% + 10px)', width: 180, left: 'initial', right: 0 }}
        >
          <div
            className="dropdown-menu__list"
            onClick={() => {
              setOpenModal(false);
              navigate('/account');
            }}
          >
            <img src={iconAccount} alt="icon-account" style={{ marginRight: 8 }} />
            Account
          </div>
          <div
            className="dropdown-menu__list"
            onClick={() => {
              setOpenModal(false);
              navigate('/billing');
            }}
          >
            <img src={iconBilling} alt="icon-billing" style={{ marginRight: 10 }} />
            Billing
          </div>
          <div
            className="dropdown-menu__list"
            onClick={() => {
              dispatch(clearAuthToken());
              shutdown();
            }}
          >
            <img src={iconLogout} alt="icon-logout" style={{ marginRight: 8 }} />
            Logout
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
