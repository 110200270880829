import CHOICES from 'CHOICES';

export const AUTH_TOKEN = 'authToken';

export const ARTICLES = {
  baseUrl: 'https://help.revli.com/en/articles',
  spf: 'https://help.revli.com/en/collections/3625518-dns-records-spf-dkim-dmarc#spf',
  dkim: 'https://help.revli.com/en/collections/3625518-dns-records-spf-dkim-dmarc#dkim',
  dmarc: 'https://help.revli.com/en/collections/3625518-dns-records-spf-dkim-dmarc#dmarc',
  blacklists:
    'https://help.revli.com/en/articles/6604840-what-do-i-need-to-know-about-email-blacklists',
  daysWarming:
    'https://help.revli.com/en/articles/6585286-how-long-should-you-warm-up-your-cold-email-inbox-for',
  domainAge:
    'https://help.revli.com/en/articles/6585284-what-is-domain-age-and-why-is-it-important',
  filterID: 'https://help.revli.com/en/collections/3634647-warm-up-settings#inbox-filter-id',
  warmupSchedule: 'https://help.revli.com/en/collections/3634647-warm-up-settings#warm-up-schedule',
  inboxSignature: 'https://help.revli.com/en/collections/3634647-warm-up-settings#inbox-signature',
  selectTopic: 'https://help.revli.com/en/collections/3634647-warm-up-settings#warm-up-topics',
  timezone: 'https://help.revli.com/en/collections/3634647-warm-up-settings#warm-up-timezone',
  helpCenter: 'https://help.revli.com/',
  termsOfUse: 'https://www.revli.com/terms',
  privacyPolicy: 'https://www.revli.com/privacy-policy',
  fwdApps: 'https://mail.google.com/mail/?authuser=#settings/fwdandpop',
  enable2StepAuthentication:
    'https://myaccount.google.com/signinoptions/two-step-verification?authuser=',
  generateAppPassword: 'https://myaccount.google.com/apppasswords?authuser=',
  helpGuideGoogleOAuth:
    'https://help.revli.com/en/articles/6577250-how-to-connect-your-gmail-google-workspace-inbox-via-google-app-id',
  helpGoogleAppPassword:
    'https://help.revli.com/en/articles/6801930-how-to-connect-your-gmail-google-workspace-inbox-via-app-password',
  helpMS365OAuth:
    'https://help.revli.com/en/articles/6809926-how-to-connect-your-outlook-microsoft-365-inbox',
  deliveribilityGuide: 'https://help.revli.com/en/collections/3789544-email-deliverability-guide'
};

export const MAILBOX_SCORE_TOOLTIP_TEXTS = {
  spf: {
    pass: 'SPF record found.',
    warning: 'Multiple SPF records found.',
    fail: 'No SPF record found.'
  },
  dkim: {
    true: 'DKIM record found.',
    false: 'No DKIM record found.'
  },
  dmarc: {
    pass: 'DMARC record found.',
    fail: 'No DMARC record found.'
  },
  blacklists: {
    true: 'Your domain is not listed on any blacklists.',
    false: 'Your domain is listed on a few blacklists.'
  },
  daysWarming: {
    pass: 'Keep warming to build and maintain your deliverability and open rates.',
    warning: 'Keep warming to improve your deliverability and open rates.',
    fail: 'Keep warming to improve your deliverability and open rates.'
  },
  domainAge: {
    text: `One of the first factors spam filters look at to evaluate the legitimacy of an email address is the domain age.
    \nTherefore, the longer your domain has been active, the better your email deliverability.`
  },
  heading: {
    text: 'Mailbox changes can take up to 24 hours to update.'
  }
};

export const TOOLTIPS = {
  startingBaseline: 'The starting number of emails we will send from day one.',

  increasePerDay: 'The number of additional emails we will send per day until we reach the maximum',

  replyRatePercentage:
    "The percentage of warm-up emails we will automatically reply to each day. This does not impact your prospect's ability to reply to non warm-up emails. These are additional emails added on top of the max email per day rate.",

  maxEmailsPerDay: 'The maximum number of emails we will send each day.'
};

export const defaultTimezone = 'America/New_York';

export const HELP_VIDEOS = {
  connectGoogle: 'https://www.youtube.com/embed/CtkuOcBBKqU',
  connectGoogleSMTP: 'https://www.youtube.com/embed/zBd_Tl-kqkY',
  connectOutlook: 'https://www.youtube.com/embed/7fPJjii5GyM',
  dashboardIntroduction: 'https://www.youtube.com/embed/pIf_yVXFyTM',
  fundingVideo: 'https://www.youtube.com/embed/moqfztNmP_I'
};

export const domainBlackListDummy = [
  {
    text: 'Passive Spam Block List',
    id: 1,
    value: true
  },
  {
    text: 'Barracuda',
    id: 2,
    value: true
  },
  {
    text: 'URLhaus',
    id: 3,
    value: false
  },
  {
    text: 'PhishTank',
    id: 4,
    value: true
  },
  {
    text: 'NordSpam',
    id: 5,
    value: false
  },
  {
    text: 'IPSpamList',
    id: 6,
    value: true
  },
  {
    text: 'Backscatterer',
    id: 7,
    value: false
  },
  {
    text: 'S5h Blacklist',
    id: 8,
    value: true
  },
  {
    text: 'HoneyDB Blacklist',
    id: 9,
    value: true
  },
  {
    text: 'Myip.ms Blacklist',
    id: 10,
    value: true
  },
  {
    text: 'ViriBack C2 Tracker',
    id: 11,
    value: true
  },
  {
    text: 'AZORult Tracker',
    id: 12,
    value: true
  }
];

export const spamFilterKindChoice = {
  GOOGLE: {
    kind: CHOICES.TestSystemInboxKind.GMAIL,
    label: 'Google'
  },
  OUTLOOK: {
    kind: 2,
    label: 'Outlook'
  },
  [CHOICES.TestSystemInboxKind.GMAIL]: 'Google',
  [CHOICES.TestSystemInboxKind.OFFICE365]: 'Outlook'
};

// Header for the csv download file
export const Headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Job Title', key: 'jobTitle' },
  { label: 'Job Departments', key: 'jobDepartments' },
  { label: 'Email', key: 'contactEmail' },
  { label: 'Email Status', key: 'emailStatus' },
  { label: 'Contact LinkedIn', key: 'contactLinkedin' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Contact City', key: 'contactCity' },
  { label: 'Contact State', key: 'contactState' },
  { label: 'Contact Mobile', key: 'contactMobile' },
  { label: 'Contact Country', key: 'contactCountry' },
  { label: 'Company Headquarters', key: 'companyHeadquarters' },
  { label: '# Employees', key: 'employee' },
  { label: 'Company Website', key: 'companyWebsite' },
  { label: 'Company LinkedIn', key: 'companyLinkedinUrl' },
  { label: 'Company Facebook', key: 'companyFacebook' },
  { label: 'Company Twitter', key: 'companyTwitter' },
  { label: 'Company Phone', key: 'companyPhone' },
  { label: 'Industries', key: 'industries' },
  { label: 'Industry Categories', key: 'industryCategories' },
  { label: 'Company Description', key: 'companyDescription' },
  { label: 'Company Technologies', key: 'companyTechnologies' },
  { label: 'Founded Date', key: 'foundedDate' },
  { label: 'Annual Revenue', key: 'annualRevenue' },
  { label: 'Recent Funding Date', key: 'lastFundingDate' },
  { label: 'Recent Funding Amount(USD)', key: 'latestFundingAmount' },
  { label: 'Last Funding Type', key: 'lastFundingType' },
  { label: 'Funding News', key: 'fundingNews' },
  { label: 'Total Funding Amount(USD)', key: 'totalFundingAmount' },
  { label: 'Funding Status', key: 'fundingStatus' },
  { label: 'Top 5 Investors', key: 'top5Investors' },
  { label: 'Currently Hiring', key: 'currentlyHiring' }
];
