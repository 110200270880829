// DON'T EDIT. THIS FILE IS GENERATED BY
// ./manage.py export_choices
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// backend/contribs/management/commands/export_choices.py
const CHOICES = {
  ActionStepsKind: {
    CHOICE_LIST: [
      {
        label: 'DKIM Steps',
        value: 'DKIM'
      },
      {
        label: 'DMARC Steps',
        value: 'DMARC'
      },
      {
        label: 'Domain Blacklist Steps',
        value: 'DOMAIN_BLACKLIST'
      },
      {
        label: 'Google Spam Filter Steps',
        value: 'GOOGLE_SPAM_FILTER'
      },
      {
        label: 'Outlook Spam Filter Steps',
        value: 'OUTLOOK_SPAM_FILTER'
      },
      {
        label: 'Spam Assasian Steps',
        value: 'SPAM_ASSASIAN'
      },
      {
        label: 'Spam Words Steps',
        value: 'SPAM_WORD'
      },
      {
        label: 'Spam Words Subject Line Steps',
        value: 'SPAM_WORD_SUBJECT_LINE'
      },
      {
        label: 'SPF Steps',
        value: 'SPF'
      }
    ],
    CHOICE_MAP: {
      DKIM: 'DKIM Steps',
      DMARC: 'DMARC Steps',
      DOMAIN_BLACKLIST: 'Domain Blacklist Steps',
      GOOGLE_SPAM_FILTER: 'Google Spam Filter Steps',
      OUTLOOK_SPAM_FILTER: 'Outlook Spam Filter Steps',
      SPAM_ASSASIAN: 'Spam Assasian Steps',
      SPAM_WORD: 'Spam Words Steps',
      SPAM_WORD_SUBJECT_LINE: 'Spam Words Subject Line Steps',
      SPF: 'SPF Steps'
    },
    DKIM: 'DKIM',
    DMARC: 'DMARC',
    DOMAIN_BLACKLIST: 'DOMAIN_BLACKLIST',
    GOOGLE_SPAM_FILTER: 'GOOGLE_SPAM_FILTER',
    OUTLOOK_SPAM_FILTER: 'OUTLOOK_SPAM_FILTER',
    SPAM_ASSASIAN: 'SPAM_ASSASIAN',
    SPAM_WORD: 'SPAM_WORD',
    SPAM_WORD_SUBJECT_LINE: 'SPAM_WORD_SUBJECT_LINE',
    SPF: 'SPF'
  },
  ArticleKind: {
    CHOICE_LIST: [
      {
        label: 'Cover Articles',
        value: 'COVER_ARTICLES'
      },
      {
        label: 'Feature Articles',
        value: 'FEATURE_ARTICLES'
      },
      {
        label: 'Latest Articles',
        value: 'LATEST_ARTICLES'
      },
      {
        label: 'Popular Posts',
        value: 'POPULAR_POSTS'
      }
    ],
    CHOICE_MAP: {
      COVER_ARTICLES: 'Cover Articles',
      FEATURE_ARTICLES: 'Feature Articles',
      LATEST_ARTICLES: 'Latest Articles',
      POPULAR_POSTS: 'Popular Posts'
    },
    COVER_ARTICLES: 'COVER_ARTICLES',
    FEATURE_ARTICLES: 'FEATURE_ARTICLES',
    LATEST_ARTICLES: 'LATEST_ARTICLES',
    POPULAR_POSTS: 'POPULAR_POSTS'
  },
  ArticleStatus: {
    CHOICE_LIST: [
      {
        label: 'Draft',
        value: 0
      },
      {
        label: 'Published',
        value: 1
      },
      {
        label: 'Deleted',
        value: 2
      }
    ],
    CHOICE_MAP: {
      0: 'Draft',
      1: 'Published',
      2: 'Deleted'
    },
    DELETED: 2,
    DRAFT: 0,
    PUBLISHED: 1
  },
  AuthInitType: {
    CHOICE_LIST: [
      {
        label: 'Gmail inboxes',
        value: 1
      },
      {
        label: 'Ms 365 Inboxes',
        value: 2
      },
      {
        label: 'Zoho Inboxes',
        value: 3
      }
    ],
    CHOICE_MAP: {
      1: 'Gmail inboxes',
      2: 'Ms 365 Inboxes',
      3: 'Zoho Inboxes'
    },
    GMAIL_INBOXES: 1,
    MS_365_INBOXES: 2,
    ZOHO_INBOXES: 3
  },
  ChecksMadeKind: {
    ATTACHMENTS: 12,
    CHOICE_LIST: [
      {
        label: 'Email Placement Result [Deliverability]',
        value: 1
      },
      {
        label: 'Domain Blacklist Check [Deliverability]',
        value: 2
      },
      {
        label: 'Spam Filter [Deliverability]',
        value: 3
      },
      {
        label: 'Spam Assasian Check [Deliverability]',
        value: 4
      },
      {
        label: 'SPF Check [Deliverability]',
        value: 5
      },
      {
        label: 'DKIM Check [Deliverability]',
        value: 6
      },
      {
        label: 'DMARC Check [Deliverability]',
        value: 7
      },
      {
        label: 'Domain Age Check [Deliverability]',
        value: 8
      },
      {
        label: 'Summary [Content Analysis]',
        value: 9
      },
      {
        label: 'Spam Words [Content Analysis]',
        value: 10
      },
      {
        label: 'Links [Content Analysis]',
        value: 11
      },
      {
        label: 'Attachments [Content Analysis]',
        value: 12
      },
      {
        label: 'Images [Content Analysis]',
        value: 13
      },
      {
        label: 'Tracking Pixel [Content Analysis]',
        value: 14
      },
      {
        label: 'Unsubscribe [Content Analysis]',
        value: 15
      },
      {
        label: 'Spam Words Subject Line [Content Analysis]',
        value: 16
      },
      {
        label: 'HTML Code [Content Analysis]',
        value: 17
      }
    ],
    CHOICE_MAP: {
      1: 'Email Placement Result [Deliverability]',
      2: 'Domain Blacklist Check [Deliverability]',
      3: 'Spam Filter [Deliverability]',
      4: 'Spam Assasian Check [Deliverability]',
      5: 'SPF Check [Deliverability]',
      6: 'DKIM Check [Deliverability]',
      7: 'DMARC Check [Deliverability]',
      8: 'Domain Age Check [Deliverability]',
      9: 'Summary [Content Analysis]',
      10: 'Spam Words [Content Analysis]',
      11: 'Links [Content Analysis]',
      12: 'Attachments [Content Analysis]',
      13: 'Images [Content Analysis]',
      14: 'Tracking Pixel [Content Analysis]',
      15: 'Unsubscribe [Content Analysis]',
      16: 'Spam Words Subject Line [Content Analysis]',
      17: 'HTML Code [Content Analysis]'
    },
    DKIM: 6,
    DMARC: 7,
    DOMAIN_AGE: 8,
    DOMAIN_BLACKLIST: 2,
    EMAIL_PLACEMENT: 1,
    HTML_CODE: 17,
    IMAGES: 13,
    LINKS: 11,
    SPAM_ASSASIAN: 4,
    SPAM_FILTER: 3,
    SPAM_WORD: 10,
    SPAM_WORD_SUBJECT_LINE: 16,
    SPF: 5,
    SUMMARY: 9,
    TRACKING_PIXEL: 14,
    UNSUBSCRIBE: 15
  },
  ContentType: {
    CHOICE_LIST: [
      {
        label: 'Failed',
        value: 1
      },
      {
        label: 'Warning',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Failed',
      2: 'Warning'
    },
    FAILED: 1,
    WARNING: 2
  },
  DashBoardAccessType: {
    ADMINISTRATIVE_VIEW: 2,
    CHOICE_LIST: [
      {
        label: 'Default View',
        value: 1
      },
      {
        label: 'Administrative View',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Default View',
      2: 'Administrative View'
    },
    DEFAULT_VIEW: 1
  },
  DynamicContentType: {
    CHOICE_LIST: [
      {
        label: 'Term Condition',
        value: 1
      },
      {
        label: 'Privacy Policy',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Term Condition',
      2: 'Privacy Policy'
    },
    PRIVACY_POLICY: 2,
    TERM_CONDITION: 1
  },
  EmailTopicKind: {
    CHOICE_LIST: [
      {
        label: 'Inbox outbound email content',
        value: 1
      },
      {
        label: 'Inbox outbound email reply',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Inbox outbound email content',
      2: 'Inbox outbound email reply'
    },
    INBOX_OUTBOUND_EMAIL_CONTENT: 1,
    INBOX_OUTBOUND_EMAIL_REPLY: 2
  },
  EmailTopicType: {
    CHOICE_LIST: [
      {
        label: 'General',
        value: 1
      },
      {
        label: 'Technology & Software',
        value: 2
      },
      {
        label: 'Staffing & Recruiting',
        value: 3
      },
      {
        label: 'Marketing & Advertising',
        value: 4
      },
      {
        label: 'Real Estate',
        value: 5
      },
      {
        label: 'Financial Services',
        value: 6
      },
      {
        label: 'Manufacturing',
        value: 7
      }
    ],
    CHOICE_MAP: {
      1: 'General',
      2: 'Technology & Software',
      3: 'Staffing & Recruiting',
      4: 'Marketing & Advertising',
      5: 'Real Estate',
      6: 'Financial Services',
      7: 'Manufacturing'
    },
    FINANCIAL_SERVICES: 6,
    GENERAL: 1,
    MANUFACTURING: 7,
    MARKETING_ADVERTISING: 4,
    REAL_ESTATE: 5,
    STAFFING_RECRUITING: 3,
    TECHNOLOGY_SOFTWARE: 2
  },
  EmailType: {
    CANCEL_SUBSCRIPTION: 11,
    CHOICE_LIST: [
      {
        label: 'Register email verification',
        value: 1
      },
      {
        label: 'Reset password',
        value: 2
      },
      {
        label: 'User onboard welcome email',
        value: 3
      },
      {
        label: 'Inbox needs reconnect email',
        value: 4
      },
      {
        label: 'System Inbox needs reconnect email',
        value: 5
      },
      {
        label: 'UserInbox/SystemInbox email account locked',
        value: 6
      },
      {
        label: 'Trial past 72 hours.',
        value: 7
      },
      {
        label: 'Trial past 144 hours.',
        value: 8
      },
      {
        label: 'Trial has ended.',
        value: 9
      },
      {
        label: 'Contact us',
        value: 10
      },
      {
        label: 'Cancel Subscription',
        value: 11
      }
    ],
    CHOICE_MAP: {
      1: 'Register email verification',
      2: 'Reset password',
      3: 'User onboard welcome email',
      4: 'Inbox needs reconnect email',
      5: 'System Inbox needs reconnect email',
      6: 'UserInbox/SystemInbox email account locked',
      7: 'Trial past 72 hours.',
      8: 'Trial past 144 hours.',
      9: 'Trial has ended.',
      10: 'Contact us',
      11: 'Cancel Subscription'
    },
    CONTACT_US: 10,
    INBOX_EMAIL_ACCOUNT_LOCKED: 6,
    INBOX_RECONNECT: 4,
    REG_VERIFICATION: 1,
    RESET_PASSWORD: 2,
    SYSTEM_INBOX_RECONNECT: 5,
    TRIAL_HAS_ENDED: 9,
    TRIAL_PAST_144_HOURS: 8,
    TRIAL_PAST_72_HOURS: 7,
    USER_ONBOARD: 3
  },
  FAQTopic: {
    ABOUT_US: 9,
    CHOICE_LIST: [
      {
        label: 'Home Page',
        value: 1
      },
      {
        label: 'FAQ',
        value: 2
      },
      {
        label: 'Email Warmup Pricing',
        value: 3
      },
      {
        label: 'Email Spam Test',
        value: 4
      },
      {
        label: 'Funding Insights Pricing',
        value: 5
      },
      {
        label: 'Meeting Scheduling Pricing',
        value: 6
      },
      {
        label: 'Funding Insights',
        value: 7
      },
      {
        label: 'Meeting Scheduling',
        value: 8
      },
      {
        label: 'About Us',
        value: 9
      },
      {
        label: 'Email Warmup',
        value: 10
      }
    ],
    CHOICE_MAP: {
      1: 'Home Page',
      2: 'FAQ',
      3: 'Email Warmup Pricing',
      4: 'Email Spam Test',
      5: 'Funding Insights Pricing',
      6: 'Meeting Scheduling Pricing',
      7: 'Funding Insights',
      8: 'Meeting Scheduling',
      9: 'About Us',
      10: 'Email Warmup'
    },
    EMAIL_SPAM_TEST: 4,
    EMAIL_WARMUP: 10,
    EMAIL_WARMUP_PRICING: 3,
    FAQ: 2,
    FUNDING_INSIGHTS: 7,
    FUNDING_INSIGHTS_PRICING: 5,
    HOME_PAGE: 1,
    MEETING_SCHEDULING: 8,
    MEETING_SCHEDULING_PRICING: 6
  },
  InboxKind: {
    CHOICE_LIST: [
      {
        label: 'GMail',
        value: 1
      },
      {
        label: 'Ms 365',
        value: 2
      },
      {
        label: 'SMTP',
        value: 3
      },
      {
        label: 'Zoho',
        value: 5
      },
      {
        label: 'GMail SMTP',
        value: 7
      }
    ],
    CHOICE_MAP: {
      1: 'GMail',
      2: 'Ms 365',
      3: 'SMTP',
      5: 'Zoho',
      7: 'GMail SMTP'
    },
    GMAIL: 1,
    GMAIL_SMTP: 7,
    MS_365: 2,
    SMTP: 3,
    ZOHO: 5
  },
  InvoiceKind: {
    CHOICE_LIST: [
      {
        label: 'Inbox Warmup',
        value: 1
      },
      {
        label: 'Funding Insights',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Inbox Warmup',
      2: 'Funding Insights'
    },
    FUNDING_INSIGHTS: 2,
    INBOX_WARMUP: 1
  },
  MethodKind: {
    CHOICE_LIST: [
      {
        label: 'Create Request',
        value: 1
      },
      {
        label: 'Update Request',
        value: 2
      },
      {
        label: 'Delete Request',
        value: 3
      }
    ],
    CHOICE_MAP: {
      1: 'Create Request',
      2: 'Update Request',
      3: 'Delete Request'
    },
    CREATE_RQ: 1,
    DELETE_RQ: 3,
    UPDATE_RQ: 2
  },
  NotificationsKind: {
    ACCOUNT_LOCKED: 2,
    CHOICE_LIST: [
      {
        label: 'Unauthorized Credentials',
        value: 1
      },
      {
        label: 'Account Locked',
        value: 2
      },
      {
        label: 'Unknown Reason',
        value: 3
      }
    ],
    CHOICE_MAP: {
      1: 'Unauthorized Credentials',
      2: 'Account Locked',
      3: 'Unknown Reason'
    },
    UNAUTHORIZED_CREDENTIALS: 1,
    UNKNOWN_REASON: 3
  },
  PlanKind: {
    CHOICE_LIST: [
      {
        label: 'Inbox Warmup',
        value: 1
      },
      {
        label: 'Funding Insights',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Inbox Warmup',
      2: 'Funding Insights'
    },
    FUNDING_INSIGHTS: 2,
    INBOX_WARMUP: 1
  },
  PlanOnBasis: {
    ANNUALLY: 2,
    CHOICE_LIST: [
      {
        label: 'Monthly',
        value: 1
      },
      {
        label: 'Annually',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Monthly',
      2: 'Annually'
    },
    MONTHLY: 1
  },
  ResendEmailKind: {
    ACCOUNT_VERIFICATION: 1,
    CHOICE_LIST: [
      {
        label: 'Account Verification',
        value: 1
      },
      {
        label: 'Forgot Password',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Account Verification',
      2: 'Forgot Password'
    },
    FORGOT_PASSWORD: 2
  },
  ServerConnectionKind: {
    CHOICE_LIST: [
      {
        label: 'Smtp',
        value: 1
      },
      {
        label: 'Imap',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Smtp',
      2: 'Imap'
    },
    IMAP: 2,
    SMTP: 1
  },
  SocialLoginProvidersKind: {
    CHOICE_LIST: [
      {
        label: 'google-oauth2',
        value: 1
      }
    ],
    CHOICE_MAP: {
      1: 'google-oauth2'
    },
    GMAIL: 1
  },
  SubscriptionSchedulesStatusKind: {
    CHOICE_LIST: [
      {
        label: 'Draft',
        value: 0
      },
      {
        label: 'Success',
        value: 1
      },
      {
        label: 'Failed',
        value: 2
      }
    ],
    CHOICE_MAP: {
      0: 'Draft',
      1: 'Success',
      2: 'Failed'
    },
    DRAFT: 0,
    FAILED: 2,
    SUCCESS: 1
  },
  SubscriptionStatusKind: {
    ACTIVE: 3,
    CANCELED: 1,
    CHOICE_LIST: [
      {
        label: 'Canceled',
        value: 1
      },
      {
        label: 'Expired',
        value: 2
      },
      {
        label: 'Active',
        value: 3
      },
      {
        label: 'Paused',
        value: 4
      }
    ],
    CHOICE_MAP: {
      1: 'Canceled',
      2: 'Expired',
      3: 'Active',
      4: 'Paused'
    },
    EXPIRED: 2,
    PAUSED: 4
  },
  SystemInboxKind: {
    CHOICE_LIST: [
      {
        label: 'GMail',
        value: 1
      },
      {
        label: 'Hotmail',
        value: 2
      },
      {
        label: 'Outlook',
        value: 3
      },
      {
        label: 'Yahoo',
        value: 4
      },
      {
        label: 'Zoho',
        value: 5
      }
    ],
    CHOICE_MAP: {
      1: 'GMail',
      2: 'Hotmail',
      3: 'Outlook',
      4: 'Yahoo',
      5: 'Zoho'
    },
    GMAIL: 1,
    HOTMAIL: 2,
    OUTLOOK: 3,
    YAHOO: 4,
    ZOHO: 5
  },
  TestSystemInboxKind: {
    CHOICE_LIST: [
      {
        label: 'GMail',
        value: 1
      },
      {
        label: 'office365',
        value: 2
      },
      {
        label: 'Yahoo',
        value: 3
      },
      {
        label: 'Zoho',
        value: 4
      }
    ],
    CHOICE_MAP: {
      1: 'GMail',
      2: 'office365',
      3: 'Yahoo',
      4: 'Zoho'
    },
    GMAIL: 1,
    OFFICE365: 2,
    YAHOO: 3,
    ZOHO: 4
  },
  TestThreadState: {
    CHOICE_LIST: [
      {
        label: 'Receive Failed',
        value: 2
      },
      {
        label: 'Received',
        value: 3
      }
    ],
    CHOICE_MAP: {
      2: 'Receive Failed',
      3: 'Received'
    },
    RECEIVED: 3,
    RECEIVE_FAILED: 2
  },
  TestViewKind: {
    CHOICE_LIST: [
      {
        label: 'Deliverability',
        value: 1
      },
      {
        label: 'Content Analysis',
        value: 2
      }
    ],
    CHOICE_MAP: {
      1: 'Deliverability',
      2: 'Content Analysis'
    },
    CONTENT_ANALYSIS: 2,
    DELIVERABILITY: 1
  },
  ThreadState: {
    CHOICE_LIST: [
      {
        label: 'Scheduled',
        value: 1
      },
      {
        label: 'Sending Failed',
        value: 2
      },
      {
        label: 'Sent',
        value: 3
      },
      {
        label: 'Receive Failed',
        value: 4
      },
      {
        label: 'Received',
        value: 5
      },
      {
        label: 'Replied',
        value: 6
      },
      {
        label: 'Reply Failed',
        value: 7
      }
    ],
    CHOICE_MAP: {
      1: 'Scheduled',
      2: 'Sending Failed',
      3: 'Sent',
      4: 'Receive Failed',
      5: 'Received',
      6: 'Replied',
      7: 'Reply Failed'
    },
    RECEIVED: 5,
    RECEIVE_FAILED: 4,
    REPLIED: 6,
    REPLY_FAILED: 7,
    SCHEDULED: 1,
    SENDING_FAILED: 2,
    SENT: 3
  },
  TimeZoneList: {
    AEST: 'Australia/ACT',
    ASIA_KOLKATA: 'Asia/Kolkata',
    CENTRAL_EUROPEAN: 'CET',
    CHOICE_LIST: [
      {
        label: '(UTC+05:30) Asia/Kolkata - IST',
        value: 'Asia/Kolkata'
      },
      {
        label: '(UTC+10:00) Australian Eastern Standard Time - AEST',
        value: 'Australia/ACT'
      },
      {
        label: '(UTC+01:00) Central European Time - CET',
        value: 'CET'
      },
      {
        label: '(UTC+09:00) Japan Standard Time - JST',
        value: 'Japan'
      },
      {
        label: '(UTC-06:00) US/Central - CST',
        value: 'US/Central'
      },
      {
        label: '(UTC-05:00) US/Eastern - EST',
        value: 'US/Eastern'
      },
      {
        label: '(UTC-08:00) US/Pacific - PST',
        value: 'US/Pacific'
      },
      {
        label: '(UTC+00:00) Universal Coordinated Time - UTC',
        value: 'UTC'
      }
    ],
    CHOICE_MAP: {
      'Asia/Kolkata': '(UTC+05:30) Asia/Kolkata - IST',
      'Australia/ACT': '(UTC+10:00) Australian Eastern Standard Time - AEST',
      CET: '(UTC+01:00) Central European Time - CET',
      Japan: '(UTC+09:00) Japan Standard Time - JST',
      'US/Central': '(UTC-06:00) US/Central - CST',
      'US/Eastern': '(UTC-05:00) US/Eastern - EST',
      'US/Pacific': '(UTC-08:00) US/Pacific - PST',
      UTC: '(UTC+00:00) Universal Coordinated Time - UTC'
    },
    JST: 'Japan',
    US_CENTRAL: 'US/Central',
    US_EASTERN: 'US/Eastern',
    US_PACIFIC: 'US/Pacific',
    UTC: 'UTC'
  },
  UploadKind: {
    CHOICE_LIST: [
      {
        label: 'Profile Picture',
        value: 1
      }
    ],
    CHOICE_MAP: {
      1: 'Profile Picture'
    },
    PROFILE_PICTURE: 1
  },
  UploadStatus: {
    CHOICE_LIST: [
      {
        label: 'Uploading',
        value: 1
      },
      {
        label: 'Uploaded',
        value: 2
      },
      {
        label: 'Processing',
        value: 3
      },
      {
        label: 'Processed',
        value: 4
      },
      {
        label: 'Error',
        value: 5
      }
    ],
    CHOICE_MAP: {
      1: 'Uploading',
      2: 'Uploaded',
      3: 'Processing',
      4: 'Processed',
      5: 'Error'
    },
    ERROR: 5,
    PROCESSED: 4,
    PROCESSING: 3,
    UPLOADED: 2,
    UPLOADING: 1
  }
};
export default CHOICES;
