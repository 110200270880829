import CHOICES from 'CHOICES';
import { toast } from 'react-toastify';
import { mutate } from 'relay';
import { ConnectAuthUserInboxMutation } from 'services/mutations/ConnectAuthUserInboxMutation';
import { GenerateAccountInitUriMutation } from 'services/mutations/GenerateAccountInitUriMutation';
import avatarGoogle from 'assets/images/avatar-google.png';
import avatarZoho from 'assets/images/avatar-zoho.png';
import avatarOutlook from 'assets/images/avatar-microsoft-365.png';
import avatarMailchimp from 'assets/images/avatar-mailchimp.png';
// import avatarSes from 'assets/images/avatar-aws-ses-logo.png';
import avatarDummy from 'assets/images/avatar-dummy.jpeg';
import avatarYahoo from 'assets/images/avatar-yahoo.png';
import { LinkEmailSpamTestResultMutation } from 'services/mutations/LinkEmailSpamTestResultMutation';
// import { GenerateGmailLoginAuthInitUriMutation } from 'services/mutations/GenerateGmailLoginAuthInitUriMutation';
import md5 from 'md5';
import React from 'react';

const showToaster = (eventType, eventMess) => {
  switch (eventType.toLowerCase()) {
    case 'success':
      toast.success(eventMess, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined
      });
      break;

    case 'warn':
      toast.warn(eventMess, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined
      });
      break;

    case 'info':
      toast.info(eventMess, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined
      });
      break;

    case 'error':
      toast.error(eventMess, {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined
      });
      break;

    default:
      toast(eventMess, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      break;
  }
};
export const debounce = (func, delay) => {
  let timeoutId;

  const debouncedFunction = (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };

  debouncedFunction.cancel = () => {
    clearTimeout(timeoutId);
  };

  return debouncedFunction;
};

const isEmpty = (val) => {
  // Stolen From: https://stackoverflow.com/a/28953167
  /*
  test results
  --------------
  []        true, empty array
  {}        true, empty object
  null      true
  undefined true
  ""        true, empty string
  ''        true, empty string
  0         false, number
  true      false, boolean
  false     false, boolean
  Date      false
  function  false
  */
  if (val === undefined) return true;

  if (
    typeof val == 'function' ||
    typeof val == 'number' ||
    typeof val == 'boolean' ||
    Object.prototype.toString.call(val) === '[object Date]'
  )
    return false;

  if (val == null || val.length === 0)
    // null or 0 length array
    return true;

  if (typeof val == 'object') if (Object.keys(val).length === 0) return true;

  return false;
};

const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const gmailInit = () => {
//   mutate({
//     mutation: GenerateGmailLoginAuthInitUriMutation,
//     input: {},
//     onSuccess: (data) => {
//       if (data.gmailLoginUri) {
//         window.location.href = data.gmailLoginUri.uri;
//       }
//     }
//   });
// };

const decodeUrl = (encodedVal) => {
  if (encodedVal.length % 4) {
    encodedVal = encodedVal + '='.repeat(4 - (encodedVal.length % 4));
  }
  return JSON.parse(window.atob(encodedVal)).tkn;
};

const inboxConfig = {
  startingBaseLine: { min: 1, max: 50 },
  increasePerDay: { min: 1, max: 4 },
  maxEmailsPerday: { min: 1, max: 50 },
  replyRate: { min: 1, max: 45 }
};

const subtractDayFromDate = (days) => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() - days);
  return newDate.toISOString().slice(0, 10);
};

const generateOAuthUri = (kind) => {
  mutate({
    mutation: GenerateAccountInitUriMutation,
    input: {
      initType: kind
    },
    onSuccess: (data) => {
      if (data.generateAuthInitUri) {
        window.location.href = data.generateAuthInitUri.uri;
      }
    },
    onErrors: (error) => {
      showToaster('error', error[0].message);
    }
  });
};

const oAuthInboxConnect = (code, kind, navigate) => {
  mutate({
    mutation: ConnectAuthUserInboxMutation,
    input: {
      authCode: code,
      connectionInitType: kind
    },
    onSuccess: (data) => {
      if (data.connectAuthUserInbox) {
        navigate(
          `/inbox-connect/config/${data.connectAuthUserInbox.connectionResponse.userInboxId}`
        );
      }
    },
    onErrors: (error) => {
      // setLoading(false);
      showToaster('error', error[0].message);
      navigate('/inbox-connect');
    }
  });
};

const handleReconnect = (id, kind, navigate) => {
  const inboxKind = kind;
  switch (inboxKind) {
    case CHOICES.InboxKind.GMAIL:
      // mutate({
      //   mutation: GenerateReconnectGmailUriMutation,
      //   input: {
      //     inboxId: id
      //   },
      //   onSuccess: (data) => {
      //     console.log({ data });
      //     if (data.generateReconnectGmailUri) {
      //       window.location.href = data.generateReconnectGmailUri.uri;
      //     }
      //   },
      //   onErrors: (error) => {
      //     showToaster('error', error[0].message);
      //   }
      // });
      generateOAuthUri(CHOICES.AuthInitType.GMAIL_INBOXES);
      break;
    case CHOICES.InboxKind.ZOHO:
      generateOAuthUri(CHOICES.AuthInitType.ZOHO_INBOXES);
      break;
    case CHOICES.InboxKind.OUTLOOK | CHOICES.InboxKind.MS_365:
      generateOAuthUri(CHOICES.AuthInitType.MS_365_INBOXES);
      // navigate(`/inbox-connect/outlook-connect?id=${id}`);
      break;
    // case CHOICES.InboxKind.SES:
    //   // navigate('/inbox-connect/ses-connect');
    //   break;
    case CHOICES.InboxKind.GMAIL_SMTP:
      console.log({ t: 'reconnecting gmail SMTP', id });
      navigate(`/inbox-connect/google-app-password?id=${id}`);
      break;
    default:
      navigate(`/inbox-connect/smtp-connect?id=${id}`);
  }
};

const sesRegions = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-southeast-3',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-north-1',
  'me-south-1',
  'sa-east-1'
];

const avatarPic = (kind) => {
  switch (kind) {
    case CHOICES.InboxKind.GMAIL:
      return avatarGoogle;
    case CHOICES.InboxKind.GMAIL_SMTP:
      return avatarGoogle;
    case CHOICES.InboxKind.MAILCHIMP:
      return avatarMailchimp;
    // case CHOICES.InboxKind.SES:
    //   return avatarSes;
    case CHOICES.InboxKind.ZOHO:
      return avatarZoho;
    case CHOICES.InboxKind.MS_365:
      return avatarOutlook;
    default:
      return avatarGoogle;
  }
};

const getTestInboxAvatarPic = (kind) => {
  switch (kind) {
    case CHOICES.TestSystemInboxKind.GMAIL:
      return avatarGoogle;
    case CHOICES.TestSystemInboxKind.ZOHO:
      return avatarZoho;
    case CHOICES.TestSystemInboxKind.OFFICE365:
      return avatarOutlook;
    case CHOICES.TestSystemInboxKind.YAHOO:
      return avatarYahoo;
    default:
      return avatarDummy;
  }
};

const copyToClipBoard = (textToCopy, successText = 'Copied Successfully!') => {
  navigator.clipboard.writeText(textToCopy).then(() => {
    showToaster('success', successText);
  });
};

const getPercent = (a, b) => {
  if (isNaN(Math.round((a / b) * 100))) {
    return 0;
  } else {
    return Math.round((a / b) * 100);
  }
};

const getRandomIndexes = (length = 3, totalLength = 10) => {
  function shuffle(array) {
    var tmp,
      current,
      top = array.length;
    if (top)
      while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
      }
    return array;
  }

  let newArr = [];
  for (var i = 0; i < totalLength; i++) {
    newArr.push(i);
  }
  return shuffle(newArr).slice(0, length);
};

const buildArrayData = ({
  received = 0,
  spam = 0,
  sent = 0,
  showShowEmailPlacementResult = false
}) => {
  let mailSpam = spam;
  let mailReceived = received;
  let mailSent = sent;
  let placementArr = [];
  for (var i = 0; i < mailSent; i++) {
    if (showShowEmailPlacementResult) {
      placementArr.push(false);
    } else {
      placementArr.push(null);
    }
  }
  let randomIndexArray = getRandomIndexes(mailReceived, mailSent);
  for (let i = 0; i < randomIndexArray.length; i++) {
    placementArr[randomIndexArray[i]] = true;
  }
  const setData = {
    received: mailReceived,
    spam: mailSpam,
    sent: mailSent,
    array: placementArr
  };
  return setData;
};

const getReadTime = (wordsCount) => {
  const wordsArray = [
    '0-50: 10 seconds',
    '50-100: 25 seconds',
    '100-200: 45 seconds',
    '200-300: 1 minute',
    '300-500: 2 minutes',
    '500-750: 3 minutes',
    '750-1000: 4 minutes',
    '1000-1500: 6 minutes',
    '1500-2000: 8 minutes',
    '2000-3000: 12 minutes',
    '3000-5000: 21 minutes'
  ];
  for (let index = 0; index < wordsArray.length; index++) {
    const [count, returnVal] = wordsArray[index].split(':');
    let [min, max] = count.split('-');
    if (wordsCount >= min && wordsCount < max) {
      return returnVal;
    }
  }
};

const getContentAnalysisScore = (score) => {
  const wordsArray = [
    //min-max: viewText-className
    '0-5: Poor-poor',
    '6-7: Fair-fair',
    '8-10: Good-good'
  ];
  for (let index = 0; index < wordsArray.length; index++) {
    const [count, returnVal] = wordsArray[index].split(':');
    let [min, max] = count.split('-');
    if (score >= min && score <= max) {
      let [text, className] = returnVal.split('-');
      return { text, className };
    }
  }
};

const linkSpamTestToUser = ({ uniqueCode, onSuccess = (e) => {}, onErrors = (e) => {} }) => {
  const input = { uniqueCode };
  if (!isEmpty(uniqueCode)) {
    mutate({
      mutation: LinkEmailSpamTestResultMutation,
      input: input,
      onSuccess: (data) => {
        onSuccess(data);
      },
      onErrors: (err) => {
        onErrors(err);
      }
    });
  }
};

function generateUniqueString(length = 12) {
  // min and max included
  let dateString = Date.now().toString(16);
  let string = (Math.random() + 1).toString(16).substring(7);
  const uniqueCode = 'rwu-' + md5(string + dateString).substring(length);
  return uniqueCode;
}

const generateEmailFromUniqueCode = (uniqueCode) => {
  return `email+${uniqueCode}@revli-test.com`;
};

const convertTextIntoX = (text) => {
  if (typeof text === 'string') return text.replace(/[a-zA-Z0-9]/g, 'x');
  return text;
};

function formatLinks(arr) {
  return arr.map((item, index) => {
    const { title, url } = extractTitleAndUrl(item);
    return (
      <React.Fragment key={index}>
        {!url ? (
          <span>{title}</span>
        ) : (
          <a href={url} className={url ? 'btn--text-blue' : ''} target={'_blank'} rel="noreferrer">
            {title}
          </a>
        )}
        {index < arr.length - 1 && ', '}
      </React.Fragment>
    );
  });
}

function extractTitleAndUrl(text) {
  const regex = /(.*?)(\s?\((https?:\/\/[^\s]+)\))?$/;
  const match = text.match(regex);
  if (match) {
    const title = match[1].trim();
    const url = match[3] ? match[3].trim() : null;

    return { title, url };
  }
  return { title: text.trim(), url: null };
}

function getJobTitles(arr) {
  return arr.map((item) => extractTitleAndUrl(item).title).join(', ');
}

export {
  showToaster,
  isEmpty,
  // gmailInit,
  decodeUrl,
  inboxConfig,
  subtractDayFromDate,
  generateOAuthUri,
  oAuthInboxConnect,
  sesRegions,
  handleReconnect,
  validEmail,
  avatarPic,
  copyToClipBoard,
  getPercent,
  getRandomIndexes,
  buildArrayData,
  getTestInboxAvatarPic,
  getReadTime,
  getContentAnalysisScore,
  linkSpamTestToUser,
  generateUniqueString,
  generateEmailFromUniqueCode,
  getJobTitles,
  formatLinks,
  convertTextIntoX
};
